import React, { useState } from 'react';
import { Card, Button, Badge, Row, Col } from 'react-bootstrap';

const CourseCard = ({ image, title, desc, originalPrice, discountedPrice, isMostPopular }) => {
  const [showFullDesc, setShowFullDesc] = useState(false);

  const toggleDescription = () => {
    setShowFullDesc(!showFullDesc);
  };

  return (
    <Card
      style={{
        width: '22rem',
        position: 'relative',
        border: '1px solid #ddd',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      }}
    >
      {/* Most Popular Badge */}
      {isMostPopular && (
        <Badge
          bg="danger"
          text="light"
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            fontSize: '0.8rem',
            fontWeight: 'bold',
          }}
        >
          MOST POPULAR
        </Badge>
      )}

      {/* Image */}
      <Card.Img
        variant="top"
        src={image}
        alt="Course Cover"
        style={{ padding: '10px', borderRadius: '8px' }}
      />

      {/* Card Body */}
      <Card.Body>
        <Card.Title
          className="text-center"
          style={{ fontSize: '1rem', fontWeight: 'bold' }}
        >
          {title}
        </Card.Title>

        {/* Dynamic Description with Read More */}
        <Card.Text
          className="text-center"
          style={{ fontSize: '0.9rem', marginBottom: '20px' }}
        >
          {showFullDesc ? desc : `${desc.substring(0, 100)}...`}
          {desc.length > 100 && (
            <Button
              variant="link"
              size="md"
              style={{ padding: 0, marginLeft: '5px' }}
              onClick={toggleDescription}
            >
              {showFullDesc ? 'Read Less' : 'Read More'}
            </Button>
          )}
        </Card.Text>

        {/* Pricing */}
        {/* <Row className="justify-content-center">
          {originalPrice && (
            <Col xs="auto">
              <span
                style={{
                  textDecoration: 'line-through',
                  color: 'gray',
                  fontSize: '0.9rem',
                }}
              >
                ₹{originalPrice}
              </span>
            </Col>
          )}
          <Col xs="auto">
            <span
              style={{
                fontWeight: 'bold',
                color: '#007bff',
                fontSize: '1rem',
              }}
            >
              ₹{discountedPrice}
            </span>
          </Col>
        </Row> */}
      </Card.Body>
    </Card>
  );
};

export default CourseCard;
