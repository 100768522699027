import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { IoStar, IoStarOutline } from 'react-icons/io5';
import { postData } from '../../services/apiService';

const TestimonialForm = ({newTestimonials}) => {
  const [formData, setFormData] = useState({
    image: null,
    name: '',
    comment: '',
    userType: 'Student',
    rating: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleStarClick = (rating) => {
    setFormData({ ...formData, rating });
  };

  const [submiting, setSubmiting] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmiting(true)
    const form = new FormData();
    form.append('photo', formData.image);  // appending the image file
    form.append('name', formData.name);    // appending other text fields
    form.append('comment', formData.comment);
    form.append('userType', formData.userType);
    form.append('rating', formData.rating);

    try {

      const res = await postData("testimonials/add-testimonial", form, {
        'Content-Type': 'multipart/form-data',  // important for file uploads
      })
      console.log('Submitted Testimonial:', res);
      alert('Testimonial submitted successfully!');
      setFormData({
        image: null,
        name: '',
        comment: '',
        userType: 'Student',
        rating: 0,
      })
      newTestimonials()
    } catch (e) {
      alert('Something went wrong, testimonial not submitted. Try again !');

    } finally {
      setSubmiting(false)
    }
  };

  return (
    <Container className="mt-5">
      <h2 className="mb-4 text-center">Submit Your Testimonial</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          {/* Image Upload */}
          <Col md={6}>
            <Form.Group controlId="formImage" className="mb-3">
              <Form.Label className='fw-bold' >Upload Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                accept="image/*"
                onChange={handleImageChange}
              />
            </Form.Group>
          </Col>

          {/* Name Input */}
          <Col md={6}>
            <Form.Group controlId="formName" className="mb-3">
              <Form.Label className='fw-bold'>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Feedback Textarea */}
        <Form.Group controlId="formFeedback" className="mb-3">
          <Form.Label className='fw-bold'>Feedback</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Write your feedback here"
            name="comment"
            value={formData.comment}
            onChange={handleInputChange}
          />
        </Form.Group>

        {/* User Type Dropdown */}
        <Form.Group controlId="formUserType" className="mb-3">
          <Form.Label className='fw-bold'>Your Role</Form.Label>
          <Form.Select
            name="userType"
            value={formData.userType}
            onChange={handleInputChange}
          >
            <option value="Student">Student</option>
            <option value="Client">Client</option>
          </Form.Select>
        </Form.Group>

        {/* Star Rating */}
        <Form.Group controlId="formRating" className="mb-4">
          <Form.Label className='fw-bold'>Rating</Form.Label>
          <div>
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                onClick={() => handleStarClick(star)}
                style={{
                  cursor: 'pointer',
                  color: star <= formData.rating ? '#ffc107' : 'grey',
                  fontSize: '1.5rem',
                }}
              >
                {star <= formData.rating ? <IoStar /> : <IoStarOutline />}
              </span>
            ))}
          </div>
        </Form.Group>

        {/* Submit Button */}
        <Button disabled={submiting} style={{ backgroundColor: "orange", border: "none" }} variant="primary" type="submit">
          {submiting ? "Submiting..." : "Submit Testimonial"}
        </Button>
      </Form>
    </Container>
  );
};

export default TestimonialForm;
