
import React from 'react'
import './TestimonialSlider.css';
import { IoStarOutline } from "react-icons/io5";
import { IoStarSharp } from "react-icons/io5";
import { FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";
import { Row ,Col} from 'react-bootstrap';


const TestimonialCard = ({ comment, name, userType, image, rating }) => {
  return (
    <div class="testimonial">
      <div class="quote">
        <FaQuoteLeft />
        <p>
          {comment}
        </p>
      </div>

      <div class="author ">
        <Row>
          <Col md={4} sm={12}>
            <center>
              <img alt="https://www.shutterstock.com/image-illustration/3d-rendering-illustration-cute-media-260nw-2191578795.jpg" height="80" src={image || "https://www.shutterstock.com/image-illustration/3d-rendering-illustration-cute-media-260nw-2191578795.jpg"} width="80" />
              </center>
          </Col>
          <Col md={8} sm={12}>
            <div class="info">
              <h3>
                {name}
              </h3>
              <p>
                {userType}
              </p>
              <div class="stars">
                {[1, 1, 1, 1, 1].map((item, index) => {
                  if (index < rating)
                    return <IoStarSharp />
                  return <IoStarOutline />
                })}

              </div>
            </div>
          </Col>
        </Row>

      </div>
    </div>
  )
}

export default TestimonialCard;