import React, { useEffect, useState } from 'react'
import '../../App.css'
import { Col, Container, Row } from 'react-bootstrap';
import TestimonialCard from './TestimonialCard';
import { fetchData } from "../../services/apiService"
import background from '../../assets/wall_background.jpg'
import './TestimonialSlider.css';
import { IoStarOutline } from "react-icons/io5";
import { FaQuoteLeft } from "react-icons/fa";
import TestimonialSkeleton from './TestimonialSkeleton';
import TestimonialForm from './TestimonialForm';

function Testimonials() {
  const [testimonials, setTestimonials] = useState([{
    id: 1,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eu sem tempor, varius quam at, luctus dui.',
    name: 'Paula Wilson',
    role: 'Media Analyst / SkyNet',
    image: 'https://via.placeholder.com/75',
    rating: 4,
  }])
  const[ isNew,setNew]=useState(false)
  const newTestimonials=()=>{
    setNew(true)
    console.log(isNew);
    
  }
  const [loading, setLoading] = useState(false); // For loading state
  const [error, setError] = useState(null); // For error state

  const loadTestimonials = async () => {
    try {
      setLoading(true); // Start loading
      const res = await fetchData("testimonials/get-testimonials");
      console.log(res);

      setTestimonials(res.data); // Set fetched data
      setError(null); // Reset error if successful
    } catch (err) {
      setError('Failed to fetch courses'); // Set error message
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    loadTestimonials()

  }, [])
  return (
    <Container
      style={{
        backgroundImage: 'url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjliVUcAB-0XZejAz6JPilf6JB8LphQMfstg&s")',
        // backgroundSize: 'cover', // Make the image cover the entire container
        // backgroundPosition: 'center', // Center the background image
        // backgroundRepeat: 'no-repeat', // Prevent repetition of the image
        // opacity: 0.8,
       paddingBottom:"5px",
      }}
    
    >
    
      <Row>

        {
          loading ? <TestimonialSkeleton /> : error != null ? <Container className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
            <Row>
              <Col>
                <div className="text-center p-4 border border-danger rounded bg-light">
                  <h4 className="text-danger">Error!</h4>
                  <p>Something went wrong!</p>
                </div>
              </Col>
            </Row>
          </Container>
            :
            testimonials.map((testimonial, index) => (
              <Col md={3} sm={4} className='mt-5'>

                <TestimonialCard {...testimonial} />


              </Col>
            ))


        }
      </Row>
      <TestimonialForm newTestimonials={newTestimonials}/>
    </Container>
  )
}

export default Testimonials