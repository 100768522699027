import React from 'react';
import { FaQuoteLeft } from 'react-icons/fa';
import { IoStarOutline } from 'react-icons/io5';

import './TestimonialSlider.css';
const TestimonialSkeleton = () => {
    return (
        <>
            {
                [1, 1].map((item) => {
                    return <div className="testimonial skeleton m-2">
                        {/* Quote Section */}
                        <div className="quote skeleton-box">
                            <FaQuoteLeft className="icon" />
                            <p className="skeleton-bar"></p>
                        </div>

                        {/* Author Section */}
                        <div className="author">
                            {/* Avatar Placeholder */}
                            <div className="skeleton-avatar"></div>

                            {/* Info Section */}
                            <div className="info">
                                <h3 className="skeleton-bar" style={{ width: "120px" }}></h3>
                                <p className="skeleton-bar" style={{ width: "200px" }}></p>

                                {/* Star Rating Skeleton */}
                                <div className="stars">
                                    {[1, 1, 1, 1, 1].map((_, index) => (
                                        <IoStarOutline key={index} className="skeleton-icon" />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                })
            }
        </>

    );
};

export default TestimonialSkeleton;
