

import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { fetchData } from '../../services/apiService';

import '../testimonials/TestimonialSlider.css';
import { Button } from 'react-bootstrap';
import CourseCard from './CourseCard';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Internship = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true); // For loading state
  const [error, setError] = useState(null); // For error state

  useEffect(() => {
    loadCourses();
    AOS.init();
  }, []);

  const loadCourses = async () => {
    try {
      setLoading(true); // Start loading
      const res = await fetchData("courses/get-course");
      console.log(res);

      setCourses(res.data); // Set fetched data
      setError(null); // Reset error if successful
    } catch (err) {
      setError('Failed to fetch courses'); // Set error message
    } finally {
      setLoading(false); // Stop loading
    }
  };


  const [visibleCards, setVisibleCards] = useState(6);

  // Function to load more cards
  const showMoreCards = () => {
    setVisibleCards(courses.length);  // Show all cards when "Know More" button is clicked
  };

  return (
    <Container className="py-4">
      <h2 className="text-center text-decoration-underline text-dark">What we offer?</h2>
      {
        loading ? <center className='d-flex dgrid'>{[1, 1, 1, 1].map((item) => <div className="testimonial skeleton m-2 " style={{ height: "300px", width: "400px" }}>
        <div className="skeleton-avatar"></div>
        <div className="info">
          <h3 className="skeleton-bar" style={{ width: "120px" }}></h3>
        </div>
      </div>)}</center> : error != null ? <Container className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
          <Row>
            <Col>
              <div className="text-center p-4 border border-danger rounded bg-light">
                <h4 className="text-danger">Error!</h4>
                <p>Something went wrong!</p>
              </div>
            </Col>
          </Row>
        </Container> : <>

          <Row className="gy-4">
            {/* Slice the courses array to show only the number of visible cards */}
            {courses.slice(0, visibleCards).map((course, index) => (
              <Col
                key={index}
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={4}
                data-aos="fade-up" // Add animation effect
                data-aos-delay={`${index * 100}`} // Stagger the animation for each card
              >
                {/* <InternshipCard
            image={course.img}
            title={course.title}
            desc={course.desc}
            
          /> */}

                <CourseCard
                  image={course.banner}
                  title={course.title}
                  desc={course.descrption}
                />
              </Col>
            ))}
          </Row>

          {/* Show "Know More" button only if there are more courses to show */}
          {visibleCards < courses.length && (
            <div className="text-center mt-4">
              <Button variant="primary" onClick={showMoreCards}>Know More</Button>
            </div>

          )}</>
      }
    </Container>
  );
};

export default Internship;